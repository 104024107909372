<template lang="pug">
  activity-table(domLayout="autoHeight", :actionBar="false")
</template>
<script>
import ActivityTable from "@/components/tables/CompanyActivityTable.vue";
export default {
  components: {
    ActivityTable,
  },
};
</script>
