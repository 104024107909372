<script>
import { Components } from "manageplaces-ui-kit";
import ActivityQuery from "@/graphql/queries/Activity.gql";

export default {
  extends: Components.BaseTable,
  apollo: {
    activity: {
      query: ActivityQuery,
      variables() {
        return { id: this.$route.params.id, after: this.after };
      },
      update(data) {
        return data.company.activity.edges.map(edge => edge.node);
      },
      result({ data }) {
        this.setRowData(this.activity);
        this.stopLoading();
        this.currentCursor = data.company.activity.pageInfo.endCursor;
        this.hasMore = data.company.activity.pageInfo.hasNextPage
      },
    },
  },
  data() {
    return {
      activity: [],
      columns: [
        { headerName: "Activity", field: "activityType", sortable: true },
        { headerName: "Resource type", field: "resourceType", sortable: true },
        { headerName: "Created", field: "createdAt", sortable: true, cellRenderer: "date" },
        { headerName: "User", field: "sender.name", sortable: true },
        { headerName: "User email", field: "sender.email", sortable: true },
      ],
      after: null,
      currentCursor: null,
      hasMore: null
    };
  },
  methods: {
    getFooter(h) {
      if(this.hasMore) {
        return h("a", {
          on: {
            click: this.loadMore
          }
        }, "Load more")
      } else {
        return []
      }
    },
    loadMore() {
      this.after = this.currentCursor
    }
  },
  mounted() {
    this.startLoading();
  },
};
</script>
